var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vs-popup',{attrs:{"classContent":"popup-example","title":"Confirm Update","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('ValidationProvider',{attrs:{"name":"FAQ_password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"danger":!!errors[0],"danger-text":errors[0],"type":"password","name":"FAQ_password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label-placeholder":"Password"},model:{value:(_vm.FAQ_password),callback:function ($$v) {_vm.FAQ_password=$$v},expression:"FAQ_password"}})]}}],null,true)}),_c('vs-button',{staticClass:"mb-2 mt-5",attrs:{"disabled":invalid,"type":"filled"},on:{"click":_vm.UpdateFaq}},[_vm._v("Confirm")])],1)]}}])})],1),_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8",attrs:{"title":"Update FAQ","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("QUESTION ")]),_c('validation-provider',{attrs:{"name":"question","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"danger":!!errors[0],"danger-text":errors[0],"height":"200px"},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("ANSWER ")]),_c('validation-provider',{attrs:{"name":"answer","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"danger":!!errors[0],"danger-text":errors[0],"height":"200px"},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})]}}],null,true)})],1)]),_c('vs-button',{staticClass:"mb-2",attrs:{"disabled":invalid,"type":"filled"},on:{"click":function($event){return _vm.openPopUp()}}},[_vm._v("SUBMIT")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }